import React from 'react'
import { HomePage as ExpertsHomePage } from '../components/pages/home'
import { HomePage as ExternalHomePage } from '../components/pages/widgets'
import { useConfig } from '~common/hooks'

const HomePage: React.FC = () => {
  const { IS_EXTERNAL } = useConfig()
  return IS_EXTERNAL === 'true' ? <ExternalHomePage /> : <ExpertsHomePage />
}

export default HomePage
