import Head from 'next/head'
import { useRouter } from 'next/router'
import React, { useEffect } from 'react'

import { redirectToProperHub } from '~common/helpers/common-link-helpers'

import { useHubs } from '~common/hooks/use-hubs'

export const HomePage: React.FC = () => {
  const router = useRouter()

  const {
    expertsHubState: {
      hasInitialised,
      permissionsForSelectedSubscription,
      listId,
      rootSubscriptionId,
      productGroupId,
      sosvProducts,
    },
  } = useHubs()

  useEffect(() => {
    if (!hasInitialised) return
    redirectToProperHub({
      listId,
      rootSubscriptionId,
      permissions: permissionsForSelectedSubscription,
      router,
      productGroupId,
      sosvProducts,
    })
  }, [
    hasInitialised,
    permissionsForSelectedSubscription,
    router,
    listId,
    rootSubscriptionId,
    productGroupId,
    sosvProducts,
  ])

  return (
    <>
      <Head>
        <title>Pharmaspectra</title>
      </Head>
    </>
  )
}
